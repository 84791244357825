import { JobStatus } from "@headbot/library";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { useAuth } from "../../useAuth";
import { QueryCacheKey } from "../QueryCacheKey";

export interface IJob {
    _id: string;
    created: string;
    _userId: string;
    couponCode: string;
    status: JobStatus;
    input: {
        gender: string;
        sourceFileIds: string[];
    };
    style: string;
    outputFileIds: string[];
    revision: number;
    attempts: number;
    completed: string | null;
    timeout: string;
    productId: string;
}

interface JobsListResponse {
    jobs: Array<IJob>;
}

const getJobList = async (config: AxiosRequestConfig, status: "completed" | "started") => {
    const uri = `${Constants.ApiRoot}/admin/job/list`;
    const { data } = await axios.get<JobsListResponse>(uri, {
        ...config,
        params: {
            status,
        },
    });
    return data;
};

export const useJobList = (status: "completed" | "started") => {
    const { authToken } = useAuth();
    const config = getAxiosRequestConfig(authToken);
    return useQuery([QueryCacheKey.Job, status], () => getJobList(config, status));
};
